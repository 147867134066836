import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const PastSupervisions = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpSupervisions(
          filter: { acf: { current: { eq: false } } }
          sort: { fields: [acf___start_date], order: DESC }
        ) {
          edges {
            node {
              slug
              title
              acf {
                location
                image {
                  source_url
                  localFile {
                    absolutePath
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data =>
      data.allWordpressWpSupervisions.edges.map(supervision => {
        return (
          <Link
            key={supervision.node.slug}
            to={`/supervision/${supervision.node.slug}`}
            state={{ prevPath: location.pathname }}
            className="md:bg-purple-800 flex hover:underline md:w-full mt-1 mb-4 rounded-lg py-2 md:p-2 md:pb-2 md:mr-8"
          >
            <div className="bg-purple-300 border-2 border-purple-500 flex-1 flex rounded-lg -mt-4 md:-mr-10 shadow-lg hover:shadow-xl p-3">
              <img
                alt=""
                className="object-cover rounded-lg h-20 w-20 mr-2 sm:m-0 m-0"
                src={`${supervision.node.acf.image.source_url}`}
              />
              <div className="md:ml-4 no-underline">
                <div className="font-bold text-xs tracking-wide uppercase">
                  {supervision.node.acf.artists}
                </div>
                <div className="font-semibold text-purple-600 leading-tight text-xs">
                  {supervision.node.acf.location}
                </div>
                <span
                  to={`/${supervision.node.slug}`}
                  className="block font-light mt-1 leading-tight text-xl md:text-xl text-gray-900"
                  dangerouslySetInnerHTML={{
                    __html: supervision.node.title,
                  }}
                />
              </div>
            </div>
          </Link>
        )
      })
    }
  />
)

export default PastSupervisions
